import React from "react";
import logo200Image from '../assets/img/logo/logo_200.png';
import bgImage3 from '../assets/img/bg2/10.jpg';
import { Card, Col, Row } from "reactstrap";
import { connect } from "react-redux";
import {
  setCurrentRole,
  setCurrentStore,
  setUserData,
  fetchActivePlanState
} from "../store/actions/action";
import { withRouter } from "react-router-dom";
import { FormGroup, Input, Label, Button } from "reactstrap";
import API from "../utils/API";
import { toastFailure } from "../utils/toast_mixin";

const CONSTANT = require("../utils/Const");

class EmployeeAuth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      showLogForm: false,
      employee_code: "",
      current_store: "",
      store_index: 0,
      bgImage3:bgImage3
    };
  }

  componentDidMount() {
    const branchNameFromPath = window.location.pathname.split("/")[1];
    if (branchNameFromPath && branchNameFromPath.length > 0) {
      this.getStoreList(branchNameFromPath);
    }
  }

  getStoreList = async (branchNameFromPath) => {
    let store_list = [];
    const all_store_result = await API.getAllStore();
    if (all_store_result.status === 200) {
      const allStore = all_store_result.data;
      for (let i = 0; i < allStore.length; i++) {
        if (allStore[i].branchName === branchNameFromPath) {
          this.setState({ current_store: allStore[i], store_index: i });
          store_list.push(allStore[i]);
          break;
        }
      }
    }
  };

  handleSubmit = async () => {
    const branchNameFromPath = window.location.pathname.split("/")[1];
    try {
      const { employee_code, current_store, store_index } = this.state;
      let { index, currentStore, lastStore } = this.props.currentStoreState;
      
      const result = await API.getEmpInfo({ emp_code: employee_code, store: current_store._id });
      const userRes = await API.getStoreOwner(current_store._id);

      if (result.status === 200 && userRes.status == 200) {
        let empResult = result.data;

        if (empResult.store.branchName != branchNameFromPath) {
          toastFailure("Sorry, login URL or Staff Code is incorrect. Please double-check and try again.")
          return;
        }

        const ownerData = userRes.data.data;

        let employeeData = {
          id: empResult._id,
          username: empResult.emp_code,
          firstname: empResult.emp_name,
          lastname: "",
          address: "",
          phone: "",
          photo: CONSTANT.subFolder + "/" + empResult.photo_id + ".png",
          acc_owner: false,
          manager_role: false,
          receptionist_role: false,
          customer_role: false,
          employee_role: true,
          default_store: empResult.store,
          store_list: [empResult.store],
          note: empResult.note,
          enabled: empResult.enabled,
          lastUpdate: empResult.createdAt,
          account: ownerData.id,
          credit: 0,
          isEmployee: true
        };
        localStorage.setItem(CONSTANT.key.USER, "");
        localStorage.setItem(CONSTANT.key.ROUTE, "");
        lastStore = currentStore;
        currentStore = current_store;
        index = store_index;
        const currentStoreData = { index, currentStore, lastStore };
        localStorage.setItem(
          CONSTANT.key.STORE,
          JSON.stringify(currentStoreData)
        );
        this.props.setUserData(employeeData);
        this.props.setCurrentStore(currentStoreData);
        this.props.fetchActivePlanState({
          id: ownerData.id,
          timeLiveStoreURLToken: ownerData.timeLiveStoreURLToken
        })
        this.onEmployeeLogin(employeeData);
  
        // EMPLOYEE DATA
  
        //perc_owner_bill: 40
        // perc_owner_tip_cash: 0
        // perc_owner_tip_card: 0
        // photo_id: "4"
        // enabled: true
        // note: "Consequatur explicabo ut unde quia. Animi quas praesentium voluptatibus tempora vero cumque voluptatem sed. Aut molestias ullam. Velit voluptatem eius consequatur architecto autem hic impedit. Deserunt assumenda et praesentium ullam dolor non."
        // _id: "5dbe02427009c516e8470008"
        // createdAt: "2019-06-23T17:29:02.048Z"
        // emp_code: "4"
        // emp_name: "Dung A"
        // store: "5dbe01fa7009c516e846fee6"
      }
    } catch (error ) {
      console.log(error);
      toastFailure("Sorry, login URL or Staff Code is incorrect. Please double-check and try again.")
    }
  };

  onEmployeeLogin = async (employeeData) => {
    let data = this.props.currentStoreState;
    let allStore = this.props.storeState;
    let currentStore = null;

    allStore = await (await API.getAllStore()).data;

    for (let i = 0; i < allStore.length; i++) {
      if (allStore[i]._id === employeeData.default_store._id) {
        currentStore = allStore[i];
        if (data.lastStore === "") data.lastStore = currentStore;
        data.currentStore = allStore[i];
        data.index = 0;
        break;
      }
    }
    
    this.props.setCurrentStore(data);
    localStorage.setItem(CONSTANT.key.STORE, JSON.stringify(data));
    this.props.setCurrentRole("employee");

    setTimeout(() => {
      console.log("/"+ data.currentStore.branchName +"/staff/dashboard");
      this.props.history.push("/"+ data.currentStore.branchName +"/staff/dashboard");
    }, 300);
  };

  render() {
    const { employee_code } = this.state;
    const bg = {
      backgroundImage: `url(${this.state.bgImage3})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      minHeight:'100%',
      minWidth:'400px',
      width:'100%',
      height:'auto',
      position: 'fixed',
      top: 0,
      left:0
    };
    return (
      <Row
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          width: '99vw',
          marginTop: '3em'
        }}
        className = "mr-0 ml-0">
        <div style={bg}></div>  
        <Col md={6} lg={4}>
          <Card body className="card-with-top-border">      
            <div className="text-center pt-4 pb-4">
                <img
                  src={logo200Image}
                  className="rounded"
                  style={{ width: 220, cursor: 'pointer' }}
                  alt="logo"
                />
            </div>
            <FormGroup>
              <Label style={{ width: "100%", textAlign: "center" }}>
                Staff Mode
              </Label>
              <Input
                style={{ textAlign: "center" }}
                type="number"
                placeholder="Enter staff code"
                value={employee_code}
                onChange={(evt) =>
                  this.setState({ employee_code: evt.target.value })
                }
              />
            </FormGroup>
            <hr />
            <Button
              disabled={employee_code.length === 0}
              size="lg"
              className="bg-gradient-theme-left border-0"
              block
              onClick={this.handleSubmit}
            >
              Enter
            </Button>
          </Card>
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    userState: state.userData,
    storeState: state.allStoreList,
    currentStoreState: state.currentStore,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentRole: (data) => dispatch(setCurrentRole(data)),
    setCurrentStore: (data) => dispatch(setCurrentStore(data)),
    setUserData: (data) => dispatch(setUserData(data)),
    fetchActivePlanState: (data) => dispatch(fetchActivePlanState(data))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EmployeeAuth));
