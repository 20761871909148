import React from 'react';
import PropTypes from '../utils/propTypes';
import Typography from '../components/Typography';
import { Row, Col } from 'reactstrap';

import {
  FaCheckCircle
  } from 'react-icons/fa'

const regex_8_24_char =/^(?=.{8,}).{8,24}$/;
const regex_lower =/^.*[a-z].*$/;
const regex_upper =/^.*[A-Z].*$/;
const regex_number =/^.*[0-9].*$/;
const validButton = <FaCheckCircle  size={'1em'} className="text-primary" />
const invalidbutton = <FaCheckCircle  size={'1em'} className="text-light" />
const PasswordValidationBlock = ({ password,confirmedPassword, ...restProps }) => {
  return (
    // <Row className="password-validation-block ml-1 mr-1" {...restProps}>
    //   <Col sm={12} md={3} className="mr-1 p-0">
    //     {regex_8_24_char.test(password)?<div>{validButton}<span className="text-muted small ml-1">8-24 characters </span></div>:<div className="text-center">{invalidbutton}<span className="text-muted small ml-1">8-24 characters </span></div>}
    //   </Col>
    //   <Col sm={12} md={3} className="ml-1 mr-1 p-0">
    //   {regex_lower.test(password)?<span>{validButton}<small mute> One lowercase </small></span>:<span>{invalidbutton}<small mute> One lowercase </small></span>}
    //   </Col>
    //   <Col sm={12} md={3} className="ml-1 mr-1 p-0">
    //   {regex_upper.test(password)?<span>{validButton}<small mute> One uppercase </small></span>:<span>{invalidbutton}<small mute> One uppercase </small></span>}
    //   </Col>
    //   <Col sm={12} md={3} className="ml-1 p-0">
    //   {regex_number.test(password)?<span>{validButton}<small mute> One number </small></span>:<span>{invalidbutton}<small mute> One number </small></span>}
    //   </Col>                  
    // </Row>
    <ul className="password-validation-block ml-2 mr-2 list-unstyled align-left" {...restProps}>
      {regex_8_24_char.test(password)?<li>{validButton}<span className="text-muted small ml-1">8-24 characters </span></li>:<li>{invalidbutton}<span className="text-muted small ml-1">8-24 characters </span></li>}
      {regex_lower.test(password)?<li>{validButton}<span className="text-muted small ml-1">One lowercase</span></li>:<li>{invalidbutton}<span className="text-muted small ml-1">One lowercase</span></li>}
      {regex_upper.test(password)?<li>{validButton}<span className="text-muted small ml-1">One uppercase</span></li>:<li>{invalidbutton}<span className="text-muted small ml-1">One uppercase</span></li>}
      {regex_number.test(password)?<li>{validButton}<span className="text-muted small ml-1">One number</span></li>:<li>{invalidbutton}<span className="text-muted small ml-1">One number</span></li>}
      {(password===confirmedPassword && password != "")?<li>{validButton}<span className="text-muted small ml-1">Matched confirmed password</span></li>:<li>{invalidbutton}<span className="text-muted small ml-1">Matched confirmed password</span></li>}
    </ul>
  );
};

PasswordValidationBlock.propTypes = {
  password: PropTypes.string,
  confirmedPassword: PropTypes.string
};

PasswordValidationBlock.defaultProps = {
  password: "",
  confirmedPassword: ""
};

export default PasswordValidationBlock;
