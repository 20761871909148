import logo200Image from '../assets/img/logo/logo_200.png';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from "react-redux";
import { setUserData } from '../store/actions/action';
import {toastFailure} from "../utils/toast_mixin";
import PasswordValidationBlock from "../components/PasswordValidationBlock";
import TermConfirmModal from "../components/TermConfirmModal";
import {
  Button, Form, FormGroup, Input, Label, FormText, FormFeedback, Row, Col, InputGroup
} from 'reactstrap';
import API from '../utils/API';
import { 
  validateEmail, 
  validatePassword,
  randomString,
  getRandomFromTo,
  validateBranchName,
} from '../utils/Utils';
// import { Dial } from 'twilio/lib/twiml/VoiceResponse';

const CONSTANT = require('../utils/Const');
// var accData = {};

class AuthForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      rePassword: '',
      checkbox: false,
      errorMessage:"",
      storeName:"",
      branchName:"",
      validBranchName: false,
      suggestBranchName: "",
      newStoreAlert: "Store name cannot be empty.",
      isChecked: false,
      storeList: [],
      modal_confirmTerm: false,
    }
  }

  componentDidMount() {
    this.fetchAllStore();
    let userData = localStorage.getItem(CONSTANT.key.USER);

    if (userData && userData.length > 0) {
      let user = JSON.parse(userData);
      if (user.id && user.id.length > 0) this.props.onLogin(user);
    } else {
      const { id } = this.props.userState;
      if (id && id.length > 0) this.props.onLogin(this.props.userState);
    }
  }

  get isLogin() {
    return this.props.authState === STATE_LOGIN;
  }

  get isSignup() {
    return this.props.authState === STATE_SIGNUP;
  }

  getDisableButton() {
    if ((this.isSignup) && (!this.state.isChecked || !this.state.validBranchName)) {
      return false;
    }

    if ((this.isSignup)&&(validatePassword(this.state.password))&&(this.state.username.length > 0)&&(this.state.password.length > 0)) {
      return false
    }

    if ((this.isLogin)&&(this.state.username.length > 0)&&(this.state.password.length > 0)) {
      return false
    } 
    
    return true
  }

  changeAuthState = authState => event => {
    event.preventDefault();
    this.props.onChangeAuthState(authState);
  };

  handleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();
    if (!this.isSignup) {
      this.callLogin();
    } else {
      this.callSignUp();
    }

  };

  callLogin() {
    let data = {
      username: this.state.username,
      password: this.state.password
    }
    API.userSignin(data)
      .then(result => {
        console.log(result);
        if (result.data.status) {
          let res = result.data.data;
          if (this.state.checkbox) localStorage.setItem(CONSTANT.key.USER, JSON.stringify(res));
          this.props.setUserData(res);
          this.props.onLogin(res);
        } else {
          this.setState({errorMessage:result.data.message})
        }
      })
      .catch(error => {
        console.log(error);
      })
  }

  fetchAllStore = () => {
    API.getAllStore()
    .then(response => {
      const storesData = response.data;
      this.setState({
        storeList: storesData
      })
    })
    .catch(error => {
    })
  }

  callSignUp = () => {
    let errorMessage="";
    const { storeList, branchName } = this.state;

    const checkUniqueStore = () => {

      for (let i = 0; i < storeList.length; i++) {
        if (branchName === storeList[i].branchName) {
          return false
        }
      } 

      return true;
    }

    if (!(this.state.checkbox)) {
      errorMessage = errorMessage + 'Agreement to our terms are policy is required. ';
      this.setState({errorMessage:errorMessage});
      return
    } else if (!validateEmail(this.state.username)) {
      errorMessage = errorMessage + 'Email is not valid. ';
      this.setState({errorMessage:errorMessage});
      return
    } else if (!validatePassword(this.state.password)) {
      errorMessage = errorMessage + 'Password is not strong enough. ';
      this.setState({errorMessage:errorMessage});
      return;      
    } else if (this.state.password !== this.state.rePassword) {
      errorMessage = errorMessage + 'Retype password does not match.'
      this.setState({errorMessage:errorMessage});
      return;      
    } else if (this.state.isChecked && !this.state.validBranchName) {
      errorMessage = errorMessage + 'Branch name does not invalid';
      this.setState({errorMessage:errorMessage});
    }else {
      let data = {
        username: this.state.username,
        password: this.state.password,
        storeName:this.state.storeName,
        branchName: this.state.branchName
      }

      if (checkUniqueStore()) {
        API.accountSignup(data)
        .then(result => {
          if (result.data.status) {
            let res = result.data.data;
            console.log('data after signup: ',result)
            this.props.toSignupStep1(res)
          } else {
            toastFailure(result.data.message)
          }
          // this.props.onLogin(accData);
        })
        .catch(error => {
          console.log(error);
        }) 
      } else {
        toastFailure("This store url has been taken, please pick a new one!")
      }
      
    }
  }
  getRandomBranchName(store, storeName) {
    let suggestBranchName = "";
    let storeNameConverted = storeName.replace(" ", "").toLowerCase();
    suggestBranchName =
      storeNameConverted + "-" + randomString(getRandomFromTo(2, 5));
    for (let i = 0; i < store.length; i++) {
      if (suggestBranchName === store[i].branchName) {
        this.getRandomBranchName(store, storeName);
        break;
      } else if (i === store.length - 1) {
        return suggestBranchName;
      }
    }
  }

  newStoreInputWarning = () => {
    const { storeName, branchName, country, state, validBranchName } = this.state;
    let alertMessage = "";

    if (!storeName) {
      alertMessage = alertMessage + "Store name cannot be empty. "
    };

    if (!branchName) {
      alertMessage = alertMessage + "Store URL is required. "
    };
    if (validBranchName!==true) {
      alertMessage = alertMessage + "Please verify your selected store URL. "
    };
    this.setState({ newStoreAlert: alertMessage });
  }

  onCheckBoxTermClick = (evt) => {
    console.log("Accept term!!! ", evt.target.checked);

    this.setState({ checkbox: evt.target.checked })
  }

  verifyBranchName () {
    const { storeList } = this.state;
    let {
      storeName,
      branchName,
    } = this.state;

    let suggestBranchName = '';
    if (validateBranchName(branchName)) {
      if (storeList && storeList.length == 0) {
        this.setState({
          validBranchName: true,
          isChecked: true
        }, () => {
          this.newStoreInputWarning()
        })
        return;
      }
      for (let i = 0; i < storeList.length; i++) {
        if (branchName === storeList[i].branchName) {
          suggestBranchName = this.getRandomBranchName(storeList, storeName);
          this.setState({
            validBranchName: false,
            suggestBranchName: 'Suggest: ' + suggestBranchName,
            isChecked: true
          }, ()=>this.newStoreInputWarning())
          break;
        }
        if (i===(storeList.length-1)) {
          this.setState({
            validBranchName: true,
            isChecked: true
          }, ()=>this.newStoreInputWarning())
        }
      }
    } else {
      suggestBranchName = this.getRandomBranchName(storeList, storeName);
      this.setState({
        validBranchName:false,
        suggestBranchName: 'Suggest: ' + suggestBranchName,
        isChecked: true
      }, ()=>this.newStoreInputWarning())
    }
  }

  renderButtonText() {
    const { buttonText } = this.props;

    if (!buttonText && this.isLogin) {
      return 'Login';
    }

    if (!buttonText && this.isSignup) {
      return 'Signup';
    }

    return buttonText;
  }

  render() {
    const {
      showLogo,
      usernameLabel,
      usernameInputProps,
      passwordLabel,
      passwordInputProps,
      confirmPasswordLabel,
      confirmPasswordInputProps,
      children,
      onLogoClick
    } = this.props;

    return (
      <Form onSubmit={this.handleSubmit}>
        {showLogo && (
          <div className="text-center pt-4 pb-4">
            <img
              src={logo200Image}
              className="rounded"
              style={{ width: 220, cursor: 'pointer' }}
              alt="logo"
              onClick={onLogoClick}
            />
          </div>
        )}
        <FormGroup>
          <Label for={usernameLabel}>{this.isSignup?"Email":usernameLabel}</Label>
          <Input 
            {...usernameInputProps} 
            value={this.state.username} 
            id={usernameLabel} 
            onChange={evt => this.setState({ username: evt.target.value })} 
            />
        </FormGroup>
        {this.isSignup && (
          <FormGroup>
            <Label>Store Name</Label>
            <Input value={this.state.storeName} onChange={evt => this.setState({ storeName: evt.target.value })} />
          </FormGroup>
        )}
        {this.isSignup && (
          <FormGroup>
            <Label>Unique Store URL</Label>
            <Row form>
              <Col sm = {9} md = {9}>
                <InputGroup>
                  <Input 
                    disabled = {this.state.storeName.length == 0}
                    value={this.state.branchName} 
                    onChange={evt => this.setState({ branchName: evt.target.value, isChecked: false })} 
                    valid = {this.state.isChecked && this.state.validBranchName}
                    invalid = {this.state.isChecked && this.state.validBranchName == false}
                  />

                </InputGroup>  
              </Col>

              <Col sm = {3} md = {3}>
                <Button color="primary"
                  className = "w-100"
                  disabled={!this.state.branchName}
                  onClick={(event) => {
                    this.verifyBranchName(event)
                  }}
                >
                  Verify URL
                </Button>
              </Col>

            </Row>
            <FormText>Your store URL will be {'www.ahasalon.com/'}{this.state.branchName?this.state.branchName:"[your input]"}</FormText>
            <FormText>Be careful! This cannot be changed once the store is created.</FormText>          
            
            {this.state.isChecked &&
                (!this.state.validBranchName ?
                  <div className = "mt-2">
                    <FormText color = "danger"> The URL is invalid or already exists, please pick another!</FormText>
                    <FormText color = "danger"> {this.state.suggestBranchName}</FormText>
                  </div>
                :
                  <div className = "mt-2">
                    <FormText color = "success"> The URL is valid.</FormText>
                  </div>
                )
            }
            
          </FormGroup>
        )}                
        <FormGroup>
          <Label for={passwordLabel}>{passwordLabel}</Label>
          <Input {...passwordInputProps} value={this.state.password} onChange={evt => this.setState({ password: evt.target.value })} />
        </FormGroup>
        {this.isSignup && (
          <FormGroup>
            <Label for={confirmPasswordLabel}>{confirmPasswordLabel}</Label>
            <Input {...confirmPasswordInputProps} value={this.state.rePassword} onChange={evt => this.setState({ rePassword: evt.target.value })} />
          </FormGroup>
        )}

        <FormGroup check>
        {!this.isSignup ?
          <Label check>
            <Input 
              type="checkbox" 
              defaultChecked = {this.state.checkbox}
              checked = {this.state.checkbox}
              onChange={(evt) => {
                this.onCheckBoxTermClick(evt)
              }} 
            />{' '}
            {'Remember me'}
          </Label>
          :
          <>
            <Input 
              type="checkbox" 
              defaultChecked = {this.state.checkbox}
              checked = {this.state.checkbox}
              onChange={() => {
                this.setState({
                  checkbox: !this.state.checkbox
                })
              }} 
            />{' '}
            I agree to the  <a href="javascript:void(0)" 
            onClick = {() => {
              this.setState({modal_confirmTerm: true})
            }}>
              {'terms & conditions'}
            </a>
          </>
          
        }
        </FormGroup>
        <div>
          <small className="text-danger"> {this.state.errorMessage}</small>
          <br/>
        </div>
        {this.isSignup && (<PasswordValidationBlock password={this.state.password} confirmedPassword={this.state.rePassword} />)}      
        
        <hr/>
        <Button
          disabled={this.getDisableButton()}
          size="lg"
          className="bg-gradient-theme-left border-0"
          block
          onClick={this.handleSubmit}>
          {this.renderButtonText()}
        </Button>
        <div className="text-center pt-1">
          <h6>or</h6>
          <h6>
            {this.isSignup ? (
              <a href="#login" onClick={this.changeAuthState(STATE_LOGIN)}>
                Login
              </a>
            ) : (
                <a href="#signup" onClick={this.changeAuthState(STATE_SIGNUP)}>
                  Signup
              </a>
              )}
          </h6>
        </div>

        <TermConfirmModal
          isOpen = {this.state.modal_confirmTerm}
          onCheckBoxTermClick = {this.onCheckBoxTermClick}
          toggle = {() => this.setState({modal_confirmTerm: false})}
          defaultChecked = {this.state.checkbox}
         />
      </Form>
    );
  }
}

export const STATE_LOGIN = 'LOGIN';
export const STATE_SIGNUP = 'SIGNUP';

AuthForm.propTypes = {
  authState: PropTypes.oneOf([STATE_LOGIN, STATE_SIGNUP]).isRequired,
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  usernameInputProps: PropTypes.object,
  passwordLabel: PropTypes.string,
  passwordInputProps: PropTypes.object,
  confirmPasswordLabel: PropTypes.string,
  confirmPasswordInputProps: PropTypes.object,
  onLogoClick: PropTypes.func,
  toSignupStep1: PropTypes.func,
  onLogin: PropTypes.func
};

AuthForm.defaultProps = {
  authState: 'LOGIN',
  showLogo: true,
  usernameLabel: 'Username/Email',
  usernameInputProps: {
    type: 'text',
  },
  passwordLabel: 'Password',
  passwordInputProps: {
    type: 'password'
  },
  confirmPasswordLabel: 'Confirm Password',
  confirmPasswordInputProps: {
    type: 'password',
    placeholder: 'Confirm your password',
  },
  onLogoClick: () => { },
  onLogin: () => { },
  toSignupStep1: () => { }
};

function mapStateToProps(state) {
  return {
    userState: state.userData
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setUserData: (data) => dispatch(setUserData(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthForm);
