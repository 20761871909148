import React from 'react';
import PropTypes from '../utils/propTypes';

import bn from '../utils/bemnames';

import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

import Typography from './Typography';
import {Link, useHistory, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {caculateDistanceDate} from '../utils/Utils'
import API from '../utils/API';
import {setUserData} from '../store/actions/action'
import Swal from 'sweetalert2';

const bem = bn.create('page');

const Page = ({
  title,
  breadcrumbs,
  tag: Tag,
  className,
  children,
  userState,
  trialDataState,
  setUserDataState,
  currentStoreState,
  history,
  ...restProps
}) => {
  const classes = bem.b('pax-sm-3', className);

  const getDays = () => {
    const now = Math.floor(Date.now() / 1000);
    const expiredDate = trialDataState.expired_date;

    return caculateDistanceDate(now, expiredDate) + 1
  }

  const goPayment = () => {
    const currentStore = currentStoreState.currentStore;
    history.push({
      pathname: `/${currentStore.branchName}/manager/setting`,
      state: {tabIndex: 4}
    });
  }

  const renderTrialMessage = () => {
    if (window.location.pathname.indexOf('booking') >= 0) {
      return null;
    }

    if (userState.account_plan === 'trial') {
      if (userState.manager_role) {
       return (<UpgradeMessage days = {getDays() || 0} onClickUpgrade = {goPayment}/>)
      } else {
        return null;
      }
    } else {
      return <a className={"text-success"} to = ""> Active </a>
    }
  }
  
  return (
    <Tag 
      className={classes} 
    {...restProps}>
      <div className={bem.e('header')}>
        {title && typeof title === 'string' ? (
          <>
            {renderTrialMessage()}
             
            <Typography type="h4" className={bem.e('title')+" text-gray"}>
              {title}
            </Typography>
          </>
        ) : (
            title
          )}
        {breadcrumbs && (
          <Breadcrumb className={bem.e('breadcrumb')}>
            <BreadcrumbItem>Home</BreadcrumbItem>
            {breadcrumbs.length &&
              breadcrumbs.map(({ name, active }, index) => (
                <BreadcrumbItem key={index} active={active}>
                  {name}
                </BreadcrumbItem>
              ))}
          </Breadcrumb>
        )}
      </div>
      {children}
    </Tag>
  );
};

Page.propTypes = {
  tag: PropTypes.component,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string,
  children: PropTypes.node,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      active: PropTypes.bool,
    })
  ),
};

Page.defaultProps = {
  tag: 'div',
  title: '',
};

export const UpgradeMessage = ({days, onClickUpgrade}) => {
  const classTrial = bem.e("trial-link");

  return (
    <i>
      <small className = "text-muted mb-1">You have {days || 0} days left of your trial</small>
      <a onClick = {onClickUpgrade} className={"text-secondary " + classTrial} to = ""> upgrade now </a>
    </i>
  )
}

function mapStateToProps(state) {
  return {
    userState: state.userData,
    trialDataState: state.trialData,
    currentStoreState: state.currentStore
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setUserDataState: (data) => dispatch(setUserData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Page));
