import React, {useState, useEffect} from 'react';
import {
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import {supportHelpLanguage, fullHelpLangague} from '../../utils/Const';
import API from '../../utils/API'
import {connect} from "react-redux"
import {setDefaultLanguage} from '../../store/actions/action';
import {
    FaGlobe
  } from 'react-icons/fa';
import "./style.scss";

export function LanguageSwitchDropDown({
    defaultLanguage, userState, className,
    setDefaultLanguage
}) {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen(prevState => !prevState);
    const listLanguage = Object.values(fullHelpLangague);

    const onChange = (index) => {
        const keyLangauge = Object.values(supportHelpLanguage)[index];

        API.updateUserLanguage(userState.id, {
            default_lang: keyLangauge
        }).then(response => {
            setDefaultLanguage(keyLangauge)
        })
        .catch(error => console.log(error))
    }

    return (
        <div className = {className}>
                <Dropdown 
                    isOpen={dropdownOpen} 
                    toggle={toggle} 
                    className = "languageswitch"
                    direction = "down"

                >
                    <DropdownToggle caret className = "languageswitch__label shadow-none mr-1">
                        <FaGlobe size={20} color= 'primary' className = "mr-2"/>
                        {/* {isFirstLoad && 'Change Help Text Lanugage'} */}
                        {fullHelpLangague[defaultLanguage]}
                    </DropdownToggle>
                    <DropdownMenu className = "languageswitch__menu shadow-sm">
                        <div className="arrow-up"></div>
                        {listLanguage.map((language, index) => {

                            return <DropdownItem onClick = {() => onChange(index)} key = {`langague_dropdown_${index}`}>
                                {language}
                            </DropdownItem>
                        })}
                    </DropdownMenu>
                </Dropdown>
        </div>
    )
}
const mapStateToProps = state => {
    return {
        defaultLanguage: state.defaultLanguage,
        userState: state.userData,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setDefaultLanguage: (data) => dispatch(setDefaultLanguage(data))
    };
};
  
export default connect(mapStateToProps, mapDispatchToProps)(LanguageSwitchDropDown);