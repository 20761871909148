// import moment from 'moment';
import axios from "axios";
import * as Utils from '../utils/Utils';

const CONSTANT = require('./Const')
var axiosInstance = axios.create({
  baseURL: CONSTANT.baseUrl
});


export default {

  getDailyEmpReport: function (query) {
    return axiosInstance.get("/api/transaction/dailyempreport", { params: query })
  },

  getEmpReport: function (query) {
    return axiosInstance.get("/api/transaction/empreport", { params: query })
  },

  getSevenFour: function (query) {
    return axiosInstance.get("/api/storereport/getsevenfour", { params: query })
  },
  getDailyStoreReport: function (query) {
    return axiosInstance.get("/api/visit/dailystorereport", { params: query })
  },
  getManagerStoreReport: function (query) {
    return axiosInstance.get("/api/storereport/generate", { params: query })
  },
  saveEvent: function (data) {
    return axiosInstance.post("/api/event", data);
  },
  getEventLimit: function (query) {
    return axiosInstance.get("/api/event/limit",  { params: query });
  },
  getApptTodayByPhone: function (query) {
    return axiosInstance.get("/api/event/phonetoday",  { params: query });
  },
  getPendingAppointment: function(query) {
    return axiosInstance.get("/api/event/getpendingevents",  { params: query })
  },
  
  updateEventById: (id, data) => {
    return axiosInstance.put("/api/event/update/" + id, data)
  },

  removeEventById: (id) => {
    return axiosInstance.delete("/api/event/delete/" + id)
  },

  // Gets all waitlist customers
  getCustomerList: function (query) {
    return axiosInstance.get("/api/customer", { params: query });
  },
  // Saves a customer to the database
  saveCustomer: function (data) {
    return axiosInstance.post("/api/customer", data);
  },

  updateCustomerById: (id, data) => {
    return axiosInstance.put("/api/customer/update/" + id, data)
  },

  unsubscribleSMS: (id) => {
    return axiosInstance.get("/api/customer/unsubscribe", { params: { id } })
  },

  findCustomerById: (id) => {
    return axiosInstance.get("/api/customer/view/" + id)
  },

  getActiveVisit: function () {
    return axiosInstance.get("/api/visit", { params: { view_status: "active" } });
  },

  getAppointmentlist: function (query) {
    query.appointment = true;
    return axiosInstance.get("/api/visit", { params: query });
  },
  getWaitlist: function (query) {
    return axiosInstance.get("/api/visit/waitlist", { params: query });
  },
  getVisitListLimitTime: function (query) {
    return axiosInstance.get("/api/visit/limittime/", { params: query });
  },
  getVisitListLimitTimeById: function (query) {
    return axiosInstance.get("/api/visit/limittimebyId/", { params: query });
  },
  addVisit: function (data) {
    return axiosInstance.post("/api/visit", data);
  },
  deleteVisit: function (id) {
    return axiosInstance.put("/api/visit/delete/" + id);
  },
  updateVisitById: (id, data) => {
    return axiosInstance.put("/api/visit/update/" + id, data)
  },

  deleteTransaction: function (id) {
    return axiosInstance.delete("/api/transaction/detail/" + id);
  },
  getTransactionList: function () {
    return axiosInstance.get("/api/transaction");
  },
  getTranListLimitTime: function (query) {
    console.log("Reach API", query)
    return axiosInstance.get("/api/transaction/limittime", { params: query });
  },
  addTransaction: function (data) {
    return axiosInstance.post("/api/transaction", data);
  },
  updateTransactionById: function (id, data) {
    return axiosInstance.put("/api/transaction/update/" + id, data)
  },

  getTransactionGroup: function () {
    return axiosInstance.get("/api/transaction/group");
  },

  // get all transactions grouped by visit for today (after start of today)
  getTransactionGroupToday: function () {
    return axiosInstance.get("/api/transaction/grouptoday");
  },
  //get all active transactions grouped by visit (after start of today)
  getTransactionGroupActiveToday: function (query) {
    query.view_status = 'active';
    return axiosInstance.get("/api/transaction/grouptoday/", {
      params: query
    })
  },
  getTransactionGroupCompletedToday: function (query) {
    query.view_status = 'completed';
    return axiosInstance.get("/api/transaction/grouptoday/", {
      params: query
    })
  },
  getCompleteListByDay: function (query) {
    return axiosInstance.get("/api/transaction/completebyday/", {
      params: {
        start: query.startOfDate,
        end: query.endOfDate,
        store: query.store
      }
    })
  },

  getCompleteListByDayById: function (query) {
    return axiosInstance.get("/api/transaction/completebydaybyid/", {
      params: {
        start: query.startOfDate,
        end: query.endOfDate,
        store: query.store,
        cust: query.cust
      }
    })
  },
  // getTransactionGroupActiveTest: function (query) {
  //   query.view_status = 'active';
  //   return axiosInstance.get("/api/transaction/grouptest/", {
  //     params: query
  //   })
  // },
  // getTransactionGroupCompletedTest: function (query) {
  //   query.view_status = 'completed';
  //   return axiosInstance.get("/api/transaction/grouptest/", {
  //     params: query
  //   })
  // },
  // updateCustById: (id, waitlistData)  => {
  //   return axiosInstance.post("/api/waitlist/update/"+id, waitlistData)
  // },
  saveService: function (data) {
    return axiosInstance.post("/api/servicelist", data);
  },
  getServiceList: function (query) {
    return axiosInstance.get("/api/servicelist", { params: query });
  },

  getCustomerReportSimple: function (query) {
    return axiosInstance.get("/api/custreport/getsimple", { params: query });
  },
  // we only use the getCustomerReportSimple, we don't actually use the one below
  getCustomerReport: function (query) {
    return axiosInstance.get("/api/custreport/get", { params: query });
  },
  getCustomerReportForGroup: function (groupId) {
    return axiosInstance.get("/api/customergrouping/custreport/" + groupId);
  },

  getSingleCustReport: function (query) {
    return axiosInstance.get("/api/custreport/custfulllookup", { params: query })
  },

  getTax: function (query) {
    return axiosInstance.get("/api/tax", { params: query });
  },
  createTax: function (data) {
    return axiosInstance.post("/api/tax", data)
  },
  getAllPromotion: function (query) {
    return axiosInstance.get("/api/promotion", { params: query });
  },
  getAllDiscount: function (query) {
    return axiosInstance.get("/api/discount", { params: query });
  },
  updatePromotionCount: (data) => {
    return axiosInstance.put("/api/promotion/updatecount", data)
  },
  updateDiscountCount: (data) => {
    return axiosInstance.put("/api/discount/updatecount", data)
  },
  getAllPromotionDetails: function (id) {
    return axiosInstance.get("/api/promotion/details/" + id);
  },
  getAllDiscountDetails: function (id) {
    return axiosInstance.get("/api/discount/details/" + id);
  },
  getAllPromotionDetailsWithUsage: function (id) {
    return axiosInstance.get("/api/promotion/detailswusage/" + id);
  },
  getAllDiscountDetailsWithUsage: function (id) {
    return axiosInstance.get("/api/discount/detailswusage/" + id);
  },
  updatePromotionById: (id, data) => {
    return axiosInstance.put("/api/promotion/update/" + id, data)
  },
  updateDiscountById: (id, data) => {
    return axiosInstance.put("/api/discount/update/" + id, data)
  },
  deletePromotion: function (id) {
    return axiosInstance.delete("/api/promotion/delete/" + id);
  },
  findDiscountCode: function(query) {
    return axiosInstance.get("/api/discount/findcode/", { params: query });
  },
  deleteDiscount: function (id) {
    return axiosInstance.delete("/api/discount/delete/" + id);
  },
  savePromotion: function (data) {
    return axiosInstance.post("/api/promotion", data);
  },
  saveDiscount: function (data) {
    return axiosInstance.post("/api/discount", data);
  },
  getAllService: function (query) {
    return axiosInstance.get("/api/servicelist/all", { params: query });
  },
  updateServiceById: (id, data) => {
    return axiosInstance.put("/api/servicelist/update/" + id, data)
  },
  deleteServiceById: function(id) {
    return axiosInstance.delete("/api/servicelist/delete/" + id)
  },

  saveProduct: function (data) {
    return axiosInstance.post("/api/product", data);
  },
  getProductList: function (query) {
    return axiosInstance.get("/api/product", { params: query });
  },
  getAllProduct: function (query) {
    return axiosInstance.get("/api/product/all", { params: query });
  },
  updateProductById: (id, data) => {
    return axiosInstance.put("/api/product/update/" + id, data)
  },
  deleteProductById: function(id) {
    return axiosInstance.delete("/api/product/delete/" + id)
  },
  updateTaxById: (id, data) => {
    return axiosInstance.put("/api/tax/update/" + id, data)
  },
  // getCustomerById: id => {
  //   return axiosInstance.get("/api/waitlist/"+id);
  // },

  // get employee information
  getEmpInfo: function (data) {
    return axiosInstance.post("/api/emp/login", data);
  },

  //get employee list
  getAllEmp: function (query) {
    return axiosInstance.get("/api/emp/all", { params: query })
  },
  saveEmp: function (data) {
    return axiosInstance.post("/api/emp", data);
  },
  getAvailableEmp: function (query) {
    return axiosInstance.get("/api/emp", { params: query })
  },
  getAvailableAppointment: function (query) {
    return axiosInstance.get("/api/store/appointment", { params: query })
  },
  findEmpById: function (id) {
    return axiosInstance.get("/api/emp/view/"+ id)
  },
  updateEmpById: (id, data) => {
    return axiosInstance.put("/api/emp/update/" + id, data)
  },
  getEmpGroup: function (query) {
    return axiosInstance.get("/api/emp/group", { params: query })
  },
  getEmpGroupId: function (query) {
    return axiosInstance.get("/api/emp/groupid", { params: query })
  },
  deleteEmpById: function(id) {
    return axiosInstance.delete("/api/emp/delete/" + id)
  },
  saveCustomerGroup: function (data) {
    return axiosInstance.post("/api/customergroup", data);
  },
  saveCustomerGrouping: function (data) {
    return axiosInstance.post("/api/customergrouping", data);
  },
  getCustomerGroupingList: function (query) {
    return axiosInstance.get("/api/customergrouping", { params: query });
  },
  getCustomerGroupList: function (query) {
    return axiosInstance.get("/api/customergroup", { params: query });
  },
  addCustomerGrouping: function (data) {
    return axiosInstance.post("/api/customergrouping", data);
  },
  addCustomerGroup: function (data) {
    return axiosInstance.post("/api/customergroup", data);
  },
  addGroupingList: function (data) {
    return axiosInstance.post("/api/customergrouping/addgrouping", data);
  },
  deleteGroupingList: function (data) {
    return axiosInstance.post("/api/customergrouping/removegrouping", data);
  },
  updateCustomerGroupById: function (id, data) {
    return axiosInstance.put("/api/customergroup/" + id, data)
  },
  // updateCustomerGroupingById: function(id,data) {
  //   return axios.put("/api/customergrouping/"+id, data)
  // },
  // deleteCustomerGroup: function(id) {
  //   return axios.delete("/api/customergroup/" + id);
  // },
  // deleteCustomerGrouping: function(id) {
  //   return axios.delete("/api/customergrouping/" + id);
  // },
  updateCustomerGroupingById: function (id, data) {
    return axiosInstance.put("/api/customergrouping/" + id, data)
  },
  deleteCustomerGroup: function (id) {
    return axiosInstance.delete("/api/customergroup/" + id);
  },
  deleteCustomerGrouping: function (id) {
    return axiosInstance.delete("/api/customergrouping/" + id);
  },
  userSignin: function (data) {
    return axiosInstance.post("/api/user/login/", data);
  },
  userSignup: function (data) {
    return axiosInstance.post("/api/user/registry/", data);
  },
  accountSignup: function (data) {
    return axiosInstance.post("/api/user/accountregistry/", data);
  },  
  userUpdate: function (id, data) {
    return axiosInstance.put("/api/user/update/" + id, data);
  },
  getUserById: function (id) {
    return axiosInstance.get("/api/user/detail/" + id);
  },  
  userDelete: function (id) {
    return axiosInstance.delete("/api/user/delete/" + id);
  },
  userUpdatePassword: function(id, data) {
    return axiosInstance.post("/api/user/resetpassword/" + id, data)
  },
  getAllReceptionist: function (query) {
    return axiosInstance.post("/api/user/all", query);
  },
  findUserByUserName: function(query) {
    return axiosInstance.get("/api/user/find", {params: query})
  },
  getAllStore: function () {
    return axiosInstance.get("/api/store");
  },
  getAllStoreOwner: function (data) {
    return axiosInstance.post("/api/store/find", data)
  },
  getStoreOwner: function(id) {
    return axiosInstance.get("/api/store/findowner/" + id)
  },
  getStoreDetails: function (id) {
    return axiosInstance.get("/api/store/details/" + id);
  },
  updateStoreById: (id, data, isFormData = false) => {
    if (isFormData) {
      const formData = Utils.getFormData(data);
      return axiosInstance({
        method: 'put',
        url: '/api/store/update/' + id,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
    } else {
      return axiosInstance.put('/api/store/update/' + id, data)
    }
  },
  updateGarelly: (data) => {
    const formData = Utils.getFormData(data);
    return axiosInstance({
      method: 'post',
      url: '/api/garelly/',
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
  },
  getImageGarelly: (store) => {
    return axiosInstance.get("/api/garelly/", {params: store})
  },

  deleteStore: function (id) {
    return axiosInstance.delete("/api/store/delete/" + id);
  },
  
  saveStore: function (data, isFormData = false) {
    if (isFormData) {
      const formData = Utils.getFormData(data);
      return axiosInstance({
        method: 'post',
        url: '/api/store',
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
    } else {
      return axiosInstance.post('/api/store', data);
    }
    
  },

  getAllAccount: function () {
    return axiosInstance.get("/api/account");
  },
  getAccountDetails: function (id) {
    return axiosInstance.get("/api/account/details/" + id);
  },
  updateAccountById: (id, data) => {
    return axiosInstance.put("/api/account/update/" + id, data)
  },
  deleteAccount: function (id) {
    return axiosInstance.delete("/api/account/delete/" + id);
  },
  createAccount: function (data) {
    return axiosInstance.post("/api/account", data);
  },

  getSmsReport: function (query) {
    return axiosInstance.get("/api/sms", { params: query });
  },
  getSmsDetails: function (id) {
    return axiosInstance.get("/api/sms/details/" + id);
  },
  deleteSmsReport: function (id) {
    return axiosInstance.delete("/api/sms/delete/" + id);
  },
  sendSms: function (data) {
    return axiosInstance.post("/api/sms/send", data);
  },
  saveSms: function (data) {
    return axiosInstance.post("/api/sms/save", data);
  },
  sendSmsToOwner: function(data) {
    return axiosInstance.post("/api/sms/sendowner", data);
  },
  savePaymentLog: function (data) {
    return axiosInstance.post("/api/payment", data);
  },
  getAllPaymentLogList: function () {
    return axiosInstance.get("/api/payment");
  },
  getPaymentLog: function (query) {
    return axiosInstance.get("/api/payment/all", { params: query });
  },

  checkPaymentLog: function (data) {
    data.payment.status = "Pending";
    return axiosInstance.post("/api/payment/check", data);
  },

  updatePaymentLogById: (id, data) => {
    return axiosInstance.put("/api/payment/update/" + id, data)
  },

  processPayment: (data) => {
    return axiosInstance.post("/api/payment/processpayment", data)
  },
  
  getAllPlanList: () => {
    return axiosInstance.get("/api/payment/plans")
  },

  getCurrentPlan: (userId) => {
    return axiosInstance.get("/api/payment/currentplan/" + userId)
  },

  cancelPlan: (userId) => {
    return axiosInstance.delete("/api/payment/cancelplan/" + userId)
  },
  paySMS: (data) => {
    return axiosInstance.post("/api/payment/paysms", data);
  },
  getTrialExpiryDate: (query) => {
    return axiosInstance.post("/api/trial/verifyStore", query);
  },
  
  upgradeAccountPlan: (query) => {
    return axiosInstance.post("/api/trial/upgradePlan", query)
  },

  getUserInfo: (storeURL, token) => {
    const data = {
      storeURL
    }
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }
    return axiosInstance.post("/api/user", data, {headers})
  },
  getAllHelpContent: (query = {}) => {
    return axiosInstance.get("/api/helpcontent", {params: query})
  },
  getUserDefaultLanguage: (userId) => {
    return axiosInstance.get("/api/helpcontent/language/" + userId);
  },
  updateUserLanguage: (userId, data) => {
    return axiosInstance.post("/api/helpcontent/language/" + userId, data)
  },
  getAccounts: (query) => {
    return axiosInstance.get("/api/user/getreportusers", {params: query})
  },
};
