import React from "react";
import "./style.css";
import {
FaDotCircle, FaTimes, FaStopwatch, FaDollarSign
} from 'react-icons/fa'
import {
  Button, Table
} from 'reactstrap';
// This file exports both the List and ListItem components

export function ShowHideBlock(props) {
  let extraClassName = props.extraClassName;
  let Show = extraClassName + " display-block"
  let Hide = extraClassName + " display-none"
  const showHideClassName = props.show ? Show : Hide;
  return (
    <div className={showHideClassName}>
      {props.children}
    </div>
  );
}
export function ServiceListListItem(props) {
  return (<button type='button' className="serviceListListItem btn" {...props}>{props.children}</button>);
}

export function RenderSelectedServiceListBlock(props){
  const {service_raw, handleRemove, selectedService} = props;  
  let services = service_raw.filter(function (value, index, arr) { return selectedService.includes(value._id) });
  let total = services.reduce((accumulator, currentValue) => {return [accumulator[0] + currentValue.duration_min,accumulator[1] + currentValue.service_price]}, [0,0]);
  return (
    services.length?<div className="gray-text">
      <strong>Summary: </strong> &nbsp;&nbsp;&nbsp;<FaStopwatch className="service-category-icon text-secondary" />{total[0]} min &nbsp;&nbsp;&nbsp;<FaDollarSign className="service-category-icon text-secondary" />{total[1]} {'&'} up
      <Table size="sm" className="table-fit ml-2 mt-1">
        <tbody>
        {services.map(service => (
          <tr key={`serviceblock_${service._id}`}>
            <td>{service.service_name}</td>
            <td>{service.duration_min}min</td>
            <td>{service.service_price}</td>
            <td> <FaTimes  size={'1.5em'} onClick={() =>handleRemove(service._id)} className="service-delete-icon text-secondary" /></td>
          </tr>        
        ))}        
        </tbody>
      </Table>
    </div>:null
    )
}

export function RenderServiceListBlock(props){
  const {service_raw, handleClick, selectedService} = props;
    let serviceList = [];
    let services = service_raw.reduce((prev, current) => {
      prev[current.category] = [...prev[current.category] || [], current];
      return prev;
    }, {});

    for (var category in services) {
      serviceList.push(
      <p  key={category} >
        <FaDotCircle className="service-category-icon text-primary" />
        <span>{category}</span>
      </p>
      );
      serviceList.push(
        <div key={`autoCol${category}`} className="auto-col mb-3">
          {services[category].map(service => {
              return (
                service.shown_at_checkin?                
                <Button
                  onClick={() => handleClick(service._id)}
                  key={service._id}
                  disabled={selectedService.includes(service._id)}
                  color={'primary'}
                  className="mr-2 mb-2"
                >
                  {service.service_name} - {service.duration_min}min - ${service.service_price}
                </Button>:null
              )
          })}
        </div>
      )
    }
    return serviceList;
}

export function RenderServiceBox(props) {
    const {handleClick, availableServices, keyPrefix} = props;  
    let serviceList = [];
    let services = availableServices.reduce((prev, current) => {
      prev[current.category] = [...prev[current.category] || [], current];
      return prev;
    }, {});

    for (var category in services) {
      serviceList.push(<span key={category} style={{ marginLeft: 15 }}>{category}</span>);
      serviceList.push(
        <div key={keyPrefix+`${category}`} className="auto-col">
          {services[category].map(service => (
            <Button
              onClick={() => handleClick(service._id)}
              key={service._id}
              color={'primary'}
              className="mr-2 mb-2"
            >
              {service.service_name} - {service.duration_min}min - ${service.service_price}
            </Button>
          ))}
        </div>
      )
    }
    return serviceList;
  }