import socketIOClient from "socket.io-client";
const CONSTANT = require('../utils/Const');

export default class AppSingleton {

    static socket = null;

    constructor() {
        
    }

    static getInstanceSocket() {
        if (this.socket == null) {
            this.socket = socketIOClient(CONSTANT.endpoint);
            return this.socket;
        } else {
            return this.socket;
        }
    }
}

