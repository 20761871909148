export const key = {
    USER: 'userData@key',
    ROUTE: 'routeData@key',
    STORE: 'storeData@Key',
    TABINDEX: 'tabIndex@Key',
};
export const baseUrl = "https://app.ahasalon.com";
export const subFolder = "";
// export const endpoint = "http://192.168.100.182:80";
// export const endpoint = "http://192.168.1.13:80";
// export const endpoint = "http://169.254.228.82:80";

// export const endpoint = "http://192.168.1.207:8080";
export const endpoint = "https://app.ahasalon.com";
// export const endpoint = "http://192.168.1.215:3000";
// export const baseUrl = "https://demo.vfftech.com/nailapp";
// export const endpoint = "https://demo.vfftech.com";
// export const subFolder = "/nailapp";

export const creditRate = 2; // rate for credit 

// these consts are for react-date
export const DISPLAY_FORMAT = 'L';
export const ISO_FORMAT = 'YYYY-MM-DD';

export const START_DATE = 'startDate';
export const END_DATE = 'endDate';

export const HORIZONTAL_ORIENTATION = 'horizontal';
export const VERTICAL_ORIENTATION = 'vertical';
export const VERTICAL_SCROLLABLE = 'verticalScrollable';

export const NAV_POSITION_BOTTOM = 'navPositionBottom';
export const NAV_POSITION_TOP = 'navPositionTop';

export const ICON_BEFORE_POSITION = 'before';
export const ICON_AFTER_POSITION = 'after';

export const INFO_POSITION_TOP = 'top';
export const INFO_POSITION_BOTTOM = 'bottom';
export const INFO_POSITION_BEFORE = 'before';
export const INFO_POSITION_AFTER = 'after';

export const ANCHOR_LEFT = 'left';
export const ANCHOR_RIGHT = 'right';

export const OPEN_DOWN = 'down';
export const OPEN_UP = 'up';

export const DAY_SIZE = 39;
export const BLOCKED_MODIFIER = 'blocked';
export const WEEKDAYS = [0, 1, 2, 3, 4, 5, 6];

export const FANG_WIDTH_PX = 20;
export const FANG_HEIGHT_PX = 10;
export const DEFAULT_VERTICAL_SPACING = 22;
export const systemGroupList = [
    "inactive_1mo",
    "inactive_2mo",
    "inactive_3mo",
    "inactive_4mo",
    "inactive_5mo",
    "inactive_6mo",
    "top_005_l1m",
    "top_010_l1m",
    "top_020_l1m",
    "top_050_l1m",
    "top_100_l1m",
    "top_200_l1m",
    "top_500_l1m",
    "top_005_l3m",
    "top_010_l3m",
    "top_020_l3m",
    "top_050_l3m",
    "top_100_l3m",
    "top_200_l3m",
    "top_500_l3m",
    "top_005_l6m",
    "top_010_l6m",
    "top_020_l6m",
    "top_050_l6m",
    "top_100_l6m",
    "top_200_l6m",
    "top_500_l6m"
  ]
  export const systemGroupListName = [
    "Inactive Last 1 Month",
    "Inactive Last 2 Months",
    "Inactive Last 3 Months",
    "Inactive Last 4 Month",
    "Inactive Last 5 Months",
    "Inactive Last 6 Months or More",
    "Last month - Top 5",
    "Last month - Top 10",
    "Last month - Top 20", 
    "Last month - Top 50",
    "Last month - Top 100",
    "Last month - Top 200",
    "Last month - Top 500",
    "Last 3 months - Top 5",
    "Last 3 months - Top 10",
    "Last 3 months - Top 20",
    "Last 3 months - Top 50",
    "Last 3 months - Top 100",
    "Last 3 months - Top 200",
    "Last 3 months - Top 500",
    "Last 6 months - Top 5",
    "Last 6 months - Top 10",
    "Last 6 months - Top 20", 
    "Last 6 months - Top 50",
    "Last 6 months - Top 100",
    "Last 6 months - Top 200",
    "Last 6 months - Top 500",
  ]
export const MODIFIER_KEY_NAMES = new Set(['Shift', 'Control', 'Alt', 'Meta']);
// Done for react-date

export const CUSTOMER_MESSAGE = `Please reply "YES {{appointment_number}}" to send your booking request to the store. We will let you know when the store confirm your booking.`;
export const STORE_MESSAGE = `Reply "ACCEPT {{appointment_number}}" to confirm booking request at {{date}}. Reply "NO {{appointment_number}}" to reject.`;
export const TEMP_REMIND_MESSAGE = `Hi {{prefix}} {{last_name}}. This is a reminder that you have an appointment with {{store_name}} on {{Date}}.Reply "YES {{appointment_number}}" to confirm. STOP receiving sms: {{stop_link}}`;

export const supportHelpLanguage = {
  vi: "vi",
  eng: "eng",
  span: "span"
}

export const fullHelpLangague = {
  vi: "Tiếng Việt",
  eng: "English",
  span: "Español"
}
export const deliveryMessageState = {
  na: "na",
  no_reply: "message_sent_no_reply",
  reply_yes: "message_sent_reply_yes",
  reply_no: "message_sent_reply_no",
  confirmed: "confirmed"
}
export const TIMEZONE = "America/Los_Angeles"
