import moment from 'moment';
const CONSTANT = require('./Const');
const dialCodes = require("../assets/dial.json");

export function validateEmail(email) {
    var re = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return re.test(String(email).toLowerCase());
}

export function randomString(length) {
    var result = '';
    // var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var characters = '0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function getRandomFromTo(min, max) {
    return Math.random() * (max - min) + min;
}

export function validateBranchName(branchName) {
    var re = /^([a-z0-9-])+$/g;
    let condition_1 = branchName.indexOf('-') !== 0;
    let condition_2 = branchName.indexOf('--') === -1;
    let condition_3 = branchName.charAt(branchName.length - 1) !== '-';
    let condition_4 = re.test(branchName);
    return(condition_1 && condition_2 && condition_3 && condition_4);
}
export function validatePhoneNumber(phoneNumber){
    var re = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
    return (re.test(phoneNumber))
}

export function validatePassword (password) {
    var re =/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,}).{8,24}$/;
    return (re.test(password))    
}
export function validText(text) {
    var letters = /^[a-zA-Z\s]*$/i;

    return letters.test(text)
}

export function validNumber(text) {
    var nunbers = /^[0-9]*$/i;

    return nunbers.test(text)
}

export function validFloat(text) {
    var nunbers = /^[0-9\.]*$/i;

    return nunbers.test(text)
}

export function validPercentNumber(text) {
    if (validNumber(text)) {
        const num = Number.parseInt(text);

        return num >= 0 && num <= 100;
    } else {
        return false;
    }
}
export function validNumberAndText(text) {
    var regex = /^[0-9a-zA-Z\s]*$/i;

    return regex.test(text)
}

export function convertRoleName(text) {
    if (text=='customer') {
        return 'CUSTOMER CHECK-IN'
    } else if (text=='front') {
        return 'RECEPTIONIST'
    } else if (text=='manager') {
        return 'MANAGER'
    } else {
        return ''
    }
}


export function checkScreenSmall (breakpoint) {
    return ['xs', 'sm','md'].includes(breakpoint)
}
export function isSameDay(a, b) {
    if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
    // Compare least significant, most likely to change units first
    // Moment's isSame clones moment inputs and is a tad slow
    return a.date() === b.date()
      && a.month() === b.month()
      && a.year() === b.year();
  }
export function priceFormatter(cell) {
    let num = parseFloat(cell);
    if (isNaN(num)) {
      return ""
    } else {
      return (num.toFixed(2))
    }
  }

/*
  @params: fullpath-> C:\\fakepath\\filename.png"
  @return : filename.png
*/
export function findImageName(fullPath) {
    const regex = /[^\\/]+\.[^\\/]+$/;
    return (fullPath.match(regex) || []).pop()
}

export function getFormData(object) {
    const formData = new FormData();
    Object.keys(object).forEach(key => {
        console.log("what news: ", key, object[key])
        if (Array.isArray(object[key])) {
            object[key].forEach(value => formData.append(key, value));
        } else {
            formData.append(key, object[key])
        }
    });
    return formData;
}

/*
    @desc: Replace string contain format tag with dataParse. List tags
        {{prefix}}, {{last_name}}, {{stop_link}}, {{store name}}, {{Date}}
    @return: String with format like Hello {{last_name}} -> 'Hello Y'
    @params message: String
    @params dataParse = {
        prefix: String
        phone_num: Int,
        last_name: String,
        store_name: String,
        appointment_date: Date,
    }
*/

export function parseSMSMessage(message, dataParse) {
    let convert_message = message.replace('{{prefix}}', dataParse.prefix);
    const stopLinkId = `${dataParse.phone_num}_${dataParse.branch_name}`;
    const stop_link =  `${CONSTANT.baseUrl}/unsubscribe?id=${stopLinkId}`;

    convert_message = convert_message.replace('{{last_name}}', dataParse.last_name);
    convert_message = convert_message.replace('{{stop_link}}', stop_link);
    convert_message = convert_message.replace('{{store name}}', dataParse.store_name);

    if (convert_message.includes('{{Date}}') && dataParse.appointment_date)
        convert_message = convert_message.replace('{{Date}}', moment(dataParse.appointment_date).format("YYYY-MM-DD hh:mm A"));
        
    return convert_message;
}
/*
@params: fromDate,toDate:  unix timespam date
@return: number day distance from fromDate, toDate
*/
export function caculateDistanceDate(fromDate, toDate) {
    const dateMomentFrom = moment.unix(fromDate);
    const dateMomentTo = moment.unix(toDate);

    const days = dateMomentTo.diff(dateMomentFrom, 'days');

    return days;
}

export function formatShortDate(date) {
    const formatStr = moment(date).format("MMM DD, YYYY").toString()

    return formatStr
}
/*
    @Decription: Create 6 unique string 
    IDEA: Seperate 6 number into 2 side
        - 4 number used for storeID
        - 2 number used for EmployeeId
        -> Combine 2 part as a result
    @params storeId: ID of Store
    @params takenIds: List id which you don't want result inside
    @return: 6 unique string
*/
export function generateUniqueEmpCode(storeId = "", takenIds = []) {
    const headStore = takeRandomIntFromStoreId(storeId).toString().padStart(4, "0");
    let checkedUnique = 0;
    while(true) {
        // random from 1 to 99
        const unCheckUniqueDouble = Math.random() * (100 - 1) + 1;
        const unCheckUniqueInt = Math.floor(unCheckUniqueDouble)

        if (
            !takenIds.includes(unCheckUniqueInt.toString) ||
            !takenIds.includes(unCheckUniqueInt)
        ) {
            checkedUnique =  unCheckUniqueInt;
            break;
        }
    }
    let tailRandomStr = checkedUnique.toString().padStart(2, "0")

    return headStore + tailRandomStr
}

function takeRandomIntFromStoreId(storeId = "") {
    let randomHex = "";
    let times = 1;
    while (times <= 3) {
        const randomIndex = Math.floor(Math.random() * (storeId.length - 1) + 0);
        randomHex = randomHex + storeId[randomIndex];
        times = times + 1;
    }

    return Number.parseInt(randomHex, 16)
}
export function getContentFromKey(contentData = [], key) {
    const content = contentData.find(item => item.key === key);

    return content ? content.content : '';
}
export function parseHTMLString(str) {
    return {__html: str.replace(/(<? *script)/gi, 'illegalscript')}
}

export const mergeRefs = (...refs) => {
    const filteredRefs = refs.filter(Boolean);
    if (!filteredRefs.length) return null;
    if (filteredRefs.length === 0) return filteredRefs[0];
    return inst => {
      for (const ref of filteredRefs) {
        if (typeof ref === 'function') {
          ref(inst);
        } else if (ref) {
          ref.current = inst;
        }
      }
    };
  };

export const seperatePhone = (phoneStr) => {
    let phone = phoneStr;
    let phone_code = "+1";

    dialCodes.every(fullPhone => {
        if (phoneStr.includes(fullPhone.dial_code)) {
            phone = phone.replace(fullPhone.dial_code, "");
            phone_code = fullPhone.dial_code;
            return false
        };

        return true
    })

    return {phone, phone_code}
}