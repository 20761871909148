import React, {useEffect, useRef} from "react";
import $ from "jquery"
import "bootstrap-select/dist/css/bootstrap-select.min.css";
import "bootstrap-select/dist/js/bootstrap-select";
const dialCodes = require("../assets/dial.json");

export default function PhoneCodeSelector({
    phone_code, className = "",
    handleInputChange
}) {
  useEffect(() => {
    $('.selectpicker').selectpicker();
    $('.bootstrap-select ul.dropdown-menu').addClass("show");
  }, []);

    return (
        <select 
        value = {phone_code}
        type="select" 
        name="phone_code"
        className  = {"selectpicker form-control " + className} 
        onChange={handleInputChange}
      >
        {dialCodes.map(dial => (
            <option 
              value = {dial.dial_code} 
              key = {`phone_key_${dial.dial_code}`}
              title = {dial.dial_code}
              data-content = {`<div class = \'d-flex justify-content-between\'> <span>${dial.name}</span> <span>${dial.dial_code}</span> </div>`}
            >
            </option>
        ))}
      </select>
    )
}