import React from "react";
import classNames from 'classnames';
import "./style.css";

// The ...props means, spread all of the passed props onto this element
// That way we don't have to define them all individually
export function DeleteBtn(props) {
  const classes = classNames('delete-btn', props.className);
  return (
    <span {...props} role="button" tabIndex="1" className={classes}>
      ✗
    </span>
  );
}
