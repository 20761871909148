import React from "react";
import "./style.css";
import classNames from 'classnames';
// This file exports the Input, TextArea, and FormBtn components

export function Input2(props) {
  return (
    <div className="form-group">
      <input className="form-control" {...props} />
    </div>
  );
}
export function CheckBox(props) {
  return (
    <div className="form-check">
      <input type="checkbox" className="form-check-input" {...props} />
      <label className = "form-check-label" htmlFor={props.id}>{props.label}</label>
    </div>
  );
}

export function MultipleSelect(props) {
  return (
    <div className="form-group">
      <select multiple className="form-control" {...props}>
        {props.children}
      </select>
    </div>
  );
}
export function SingleSelect(props) {
  return (
    <div className="form-group">
      <select className="form-control" {...props}>
        {props.children}
      </select>
    </div>
  );
}

export function SelectOption(props) {
  return (
    <option {...props}>
        {props.children}
    </option>
  );
}

export function TextArea(props) {
  return (
    <div className="form-group">
      <textarea className="form-control" {...props} />
    </div>
  );
}

export function FormBtn(props) {
  const classes = classNames("btn", props.className);
  return (
    <button {...props} style={{ float: "right"}} className = {classes}>
      {props.children}
    </button>
  );
}

export function FormBtnNoFlow(props) {
  return (
    <button {...props} style={{ display: "inline-block", marginLeft:"5px", marginBottom:"5px"}} className="btn btn-primary">
      {props.children}
    </button>
  );
}

export function FormRow(props) {
  return (
    <form  style={{ width:"100%"}}>
      <div className="form-row" {...props}>
        {props.children}
      </div>
    </form>
  );
};

export function InputGroup(props) {
  return (
    <div className="input-group">
      {props.children}
    </div>
  );
};

export function FormAutoCol(props) {
  return (
    <div className="col-auto" {...props}>
      {props.children}
    </div>
  );
};
export function SwitchButton(props) {
  return (
    <div className="material-switch">
        <input id={props.id} name={props.name} onChange={props.onChange} checked={props.checked} type={props.type}/>
        <label htmlFor={props.id} className={props.color}></label>
    </div>
  );
};

export function ButtonToggleGroup(props) {
  return (
  <div className="btn-group btn-group-toggle" {...props}>
    {props.children}
  </div>
  );
};
export function Label(props) {
  return (
  <label {...props}>
    {props.children}
  </label>
  );
};