
export const fetchdata = () => {
    return { type: "FETCH_DATA" };
}

export const fetchDailyReport = (data) => {
    return { type: "FETCH_DAILY_REPORT", data };
}

export const fetchManagerStoreReport = (data) => {
    return { type: "FETCH_MANAGER_STORE_REPORT", data };
}
export const fetchWaitlistPage = (data) => {
    return { type: "FETCH_WAITLIST_PAGE", data };
}
export const fetchAllAppointment = (data) => {
    return { type: "FETCH_ALL_APPOINTMENT", data };
}

export const fetchCustomerReportSimple = (data) => {
    return { type: "FETCH_CUST_REPORT_SIMPLE", data };
}
export const fetchCustomerReportForGroup = (data) => {
    return { type: "FETCH_CUST_REPORT_FOR_GROUP", data };
}
export const fetchWaitlistPageFull = (data) => {
    return { type: "FETCH_WAITLIST_PAGE_FULL", data };
}

export const fetchCheckinPage = (data) => {
    return { type: "FETCH_CHECKIN_PAGE", data };
}

export const fetchPromotionPage = (data) => {
    return { type: "FETCH_PROMOTION_PAGE", data };
}

export const fetchCheckinPageFull = (data) => {
    return { type: "FETCH_CHECKIN_PAGE_FULL", data };
}

export const fetchCompleteList = (data) => {
    return { type: 'FETCH_DATA_TRANSACTION_GROUP', data };
}

export const fetchTransactionList = () => {
    return { type: 'FETCH_DATA_TRANSACTION' };
}

export const fetchAvailableService = (data) => {
    return { type: 'FETCH_DATA_SERVICE', data};
}
export const fetchBooking = (data) => {
    return { type: 'FETCH_BOOKING',data };
}

export const fetchStorePage = (store_list) => {
    return { type: "FETCH_STORE_PAGE", store_list };
}

export const fetchdataMinor = () => {
    return { type: "FETCH_DATA_MINOR" };
}
// fetch all data
export const fetchdataManager = () => {
    return { type: "FETCH_DATA_MANAGER" };
}

// fetch selected data
export const fetchdataManagerSelect = (data) => {
    switch (data.select) {
        case "Emp":
            return { type: "FETCH_DATA_MANAGER_EMP", data };

        case "Service":
            return { type: "FETCH_DATA_MANAGER_SERVICE", data }; // default all

        case "Customer":
            return { type: "FETCH_DATA_MANAGER_CUSTOMER", data };// default all

        case "Tax":
            return { type: "FETCH_DATA_MANAGER_TAX", data };// default all

        case "Visit":
            return { type: "FETCH_DATA_MANAGER_VISIT", data };

        case "Transaction":
            return { type: "FETCH_DATA_MANAGER_TRANSACTION", data };

        case "Customergroup":
            return { type: "FETCH_DATA_MANAGER_CUSTOMERGROUP", data };// default all
        case "Promotion":
            return { type: "FETCH_DATA_MANAGER_PROMOTION", data };// default all
        case "Product":
            return { type: "FETCH_DATA_MANAGER_PRODUCT", data };// default all
        case "Appointment":
            return { type: "FETCH_DATA_MANAGER_STORE", data };// default all
        case "Customergrouping":
            return { type: "FETCH_DATA_MANAGER_CUSTOMERGROUPING", data };// default all

        default:
            break;
    };
};

export const fetchCustomerGroup = (data) => {
    return { type: "FETCH_DATA_MANAGER_CUSTOMERGROUP", data };
}

export const fetchdataManagerEmp = (data) => {
    return { type: "FETCH_DATA_MANAGER_EMP", data };
};

export const fetchdataManagerProduct = (data) => {
    return { type: "FETCH_DATA_MANAGER_PRODUCT", data };
};

export const fetchdataManagerPromotion = () => {
    return { type: "FETCH_DATA_MANAGER_PROMOTION" };
};

export const fetchdataManagerService = () => {
    return { type: "FETCH_DATA_MANAGER_SERVICE" };
};
export const fetchdataManagerCustomer = (data) => {
    return { type: "FETCH_DATA_MANAGER_CUSTOMER", data };
};
export const fetchdataManagerVisit = () => {
    return { type: "FETCH_DATA_MANAGER_VISIT" };
};
export const fetchdataManagerTransaction = (data) => {
    return { type: "FETCH_DATA_MANAGER_TRANSACTION", data };
};

export const setUserData = data => {
    return { type: "SET_USER_DATA", data };
};

export const setCurrentRole = data => {
    return { type: "SET_CURRENT_ROLE", data };
};

export const clearUserData = () => {
    return { type: "CLEAR_USER_DATA" };
};

export const setStoreData = (data) => {
    return { type: "SET_STORE_DATA", data };
}

export const setStoreOwnerData = (data) => {
    return { type: "SET_STORE_OWNER_DATA", data };
}

export const setCurrentStore = data => {
    return { type: "SET_CURRENT_STORE", data };
};

export const fetchReceptionistPage = (data) => {
    return { type: "FETCH_RECEPTIONIST_PAGE", data };
}

export const setReceptionistData = (data) => {
    return { type: "SET_RECEPTIONIST_DATA", data };
}

export const fetchSmsReportPage = (data) => {
    return { type: "FETCH_DATA_SMS_REPORT", data };
}
export const fetchAllCustomer = (data) => {
    return { type: "FETCH_ALL_DATA_CUSTOMER", data };
}

export const setCurrenBarIndex = data => {
    return { type: "SET_CURRENT_BAR_INDEX", data };
};

export const setRecipientList = data => {
    return { type: "SET_RECIPIENT_LIST", data };
};

export const updateEmp = (newEmp) => {
    return {
        type: "UPDATE_EMP", newEmp
    };
};

export const setSideBarClick = data => {
    return {
        type: "SET_SIDEBAR_CLICK", data
    };
};

export const fetchInfoUser = (data) => {
    return {
        type: "GET_USER", data
    };
};

export const fetchTrialExpired = (data) => {
    return {
        type: "GET_STORE_TRIAL_EXPIRED",
        data
    }
}
export const fetchCurrentSubcription = (data) => {
    return {
        type: "GET_CURRENT_SUBCRIPTION",
        data
    }
}

export const fetchActivePlanState = (data) => {
    return {
        type: "PLAN_ACTIVE_STATUS",
        data
    }
}
export const fetchAllContent = () => {
    return {
        type: "FETCH_ALL_CONTENT"
    }   
}
export const setDefaultLanguage = (language) => {
    return {
        type: "SET_DEFAULT_LANGUAGE",
        data: language
    }
}
export const fetchDefaultLanguage = (data) => {
    return {
        type: "FETCH_DEFAULT_LANGUAGE",
        data: data
    }
}

export const toggleHelperModal = (key = '') => {
    return {
        type: "TOGGLE_HELPER_MODAL",
        data: key
    }
}

export const fetchPendingAppointmment = (data) => {
    return {
        type: "FETCH_PENDING_APPOINTMENT",
        data: data
    }
}

export const fetchGarellyImages = (data) => {
    return {
        type: "FETCH_GARELLY_IMAGE",
        data: data
    }
}