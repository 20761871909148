import AuthForm, { STATE_LOGIN } from '../components/AuthForm';
import SignupFormStep1 from '../components/SignupFormStep1';
import SignupFormStep2 from '../components/SignupFormStep2';
import bgImage1 from '../assets/img/bg2/9.jpg';
import bgImage2 from '../assets/img/bg2/10.jpg';
import bgImage3 from '../assets/img/bg2/16.jpg';
import React from 'react';
import { Card, Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { setCurrentRole, setCurrentStore, fetchTrialExpired, fetchActivePlanState, fetchAllContent, fetchDefaultLanguage, fetchPendingAppointmment } from '../store/actions/action';
import {toastSuccess, toastFailure} from "../utils/toast_mixin";
import {
  withRouter
} from 'react-router-dom';
import API from '../utils/API';
import {useQuery} from '../hooks/useQuery'
import Swal from 'sweetalert2';

const CONSTANT = require('../utils/Const');
let cacheStep = -1;
class AuthPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      showLogForm: false,
      step: cacheStep >= 0 ? cacheStep : 0,
      step1Data:"",
      bgImage1:bgImage1,
      bgImage2:bgImage2,
      bgImage3:bgImage3,
      storeName: '',
      accessToken: ''
    }
  }

  componentDidUpdate() {

    // const { id } = this.props.userState;
    // if (id && id.length > 0){
    //   this.onLoginAction(this.props.userState);
    // }else{

    // }

  }
  componentWillUnmount() {
    cacheStep = -1;
  }

  /*
    CONTEXT: User enter login page.
      App need check authenticate from storage and redirect without enter login form
  */
  componentDidMount() {
    const { id } = this.props.userState;
    const query = useQuery();
    const storeName = query.get('storename');
    const key = query.get('key');

    if (id && id.length > 0) {
      // TODO: App need check page had redirected from landing page or not
      // If so, show sign up form step 2

      if (!key) {
        this.onLoginAction(this.props.userState);
        return;
      }
      
      this.setState({ 
        showLogForm: true,
        storeName,
        accessToken: key,
        step: cacheStep >= 0 ? cacheStep : 1
      }, () => {
        // call API to persist data
        cacheStep = 1;
        this.signOut()
        this.handleSignUpFromLanding(storeName, key);
      })

    } else {
      if (storeName && key) {
        this.setState({ 
          showLogForm: true,
          storeName,
          accessToken: key,
          step: cacheStep >= 0 ? cacheStep : 1
        }, () => {
          // call API to persist data
          cacheStep = 1;
          this.signOut()
          this.handleSignUpFromLanding(storeName, key);
        })
      } else {
        this.setState({ showLogForm: true })
      }
    }
  }

  handleAuthState = authState => {
    if (authState === STATE_LOGIN) {
      console.log("handleAuthState")
      this.props.history.push('/login');
    } else {
      this.props.history.push('/signup');
    }
  };

  handleLogoClick = () => {
    this.props.history.push('/');
  };
  toSignupStep1 = (step1Data) => {
    this.setState({step:1, step1Data:step1Data}, () => {
      cacheStep = 1
    })
  }
  toSignupStep2 = (step2Data) => {
    const {default_store} = this.state.step1Data
    // const {username, account, default_store,id} = this.state.step1Data
    let {
      country,
      state,
      city,
      streetAddress
    } = step2Data;

    const newStoreObject = {
      country,
      state,
      city,
      streetAddress
    };    
    API.updateStoreById(default_store, newStoreObject)
      .then((res) => {
        this.setState({step:2}, () => {
          cacheStep = 2;
        })
        toastSuccess("Store updated.");
      })
      .catch((err) => console.log(err));    
  }

  handleSignUpFromLanding = (storeURL, accessToken) => {
    API.getUserInfo(storeURL, accessToken)
    .then(userResponse => {
      const userData = userResponse.data.data;
      
      this.setState({
        step1Data: userData
      })
      
    })
    .catch(error => {
      console.log(error);
      toastFailure("Cannot verify your access key");
      this.setState({
        step: 0
      }, () => {
        cacheStep = 0;
      })
    })
  }

  onLoginAction = async (user) => {
    let data = this.props.currentStoreState;

    let allStore = this.props.storeState;
    let currentStore = null;

    allStore = await (await API.getAllStore()).data;
    // user.manager_role
    if (user) {
      for (let i = 0; i < allStore.length; i++) {
        if (allStore[i]._id === user.default_store) {
          currentStore = allStore[i];
          // if (data.lastStore == '') data.lastStore = currentStore;
          data.lastStore = "";
          data.currentStore = allStore[i];
          data.index = i;
          break;
        }
      }

      // CHECK TRIAL TOKEN TO RENDER ROUTER IN APP.JS WORK PERFECT
  
      this.props.fetchActivePlanState({
        id: user.id,
        timeLiveStoreURLToken: user.timeLiveStoreURLToken
      })

      this.props.fetchTrialExpired({
        timeLiveStoreURLToken: user.timeLiveStoreURLToken
      })

      this.props.fetchAllContent()
      this.props.fetchDefaultLanguage({
        id: user.id
      })

      this.props.fetchPendingAppointmment({
        store: data.currentStore._id
      })
      this.props.setCurrentStore(data);
      localStorage.setItem(CONSTANT.key.STORE, JSON.stringify(data));
      this.routerWithRole(user);
    } else {
      let currentStoreState = '';
      let currentStoreData = localStorage.getItem(CONSTANT.key.STORE);

      if (currentStoreData && currentStoreData.length > 0) {
        currentStoreState = JSON.parse(currentStoreData);
      } else {
        currentStoreState = this.props.currentStoreState;
        if (currentStoreState.lastStore === '') currentStoreState.lastStore = currentStore;
        currentStoreState.currentStore = allStore[0];
        currentStoreState.index = 0;
      }

      this.props.setCurrentStore(currentStoreState);
      localStorage.setItem(CONSTANT.key.STORE, JSON.stringify(currentStoreState));
      this.routerWithRole(user);
    }
  }

  signOut() {
    localStorage.setItem(CONSTANT.key.USER, '');
    localStorage.setItem(CONSTANT.key.ROUTE, '');
    localStorage.setItem(CONSTANT.key.STORE, '');
    localStorage.setItem(CONSTANT.key.TABINDEX, 0);
    // this.props.onLogout();
  }

  routerWithRole = async (user) => {
    let route = localStorage.getItem(CONSTANT.key.ROUTE);
    // let store = JSON.parse(localStorage.getItem(CONSTANT.key.STORE));
    let currentBranchName = await this.getCurrentBranchName(user);
    const role = this.getUserRole(user);
    console.log('currentBranchName', currentBranchName);
    console.log("current role nek ", role)
    if (route && route.length > 0) {
      this.props.setCurrentRole(role);
      // this.props.history.push("/" + role);
      this.props.history.push(route);
    } else {
      switch (role) {
        case 'manager':
          this.props.setCurrentRole('manager');
          this.props.history.push('/' + currentBranchName + '/manager');
          localStorage.setItem(CONSTANT.key.ROUTE, '/' + currentBranchName + '/manager');
          break;
        case 'front':
          this.props.setCurrentRole('front');
          this.props.history.push('/' + currentBranchName + '/front');
          localStorage.setItem(CONSTANT.key.ROUTE, '/' + currentBranchName + '/front');
          break;
        default:
          this.props.setCurrentRole('customer');
          this.props.history.push('/' + currentBranchName + '/customer');
          localStorage.setItem(CONSTANT.key.ROUTE, '/' + currentBranchName + '/customer');
          break;
      }
    }
  }

  getUserRole(user) {
    if (user.manager_role) {
      return 'manager'
    } else if (user.receptionist_role) {
      return 'front'
    } else {
      return 'customer'
    }
  }

  getCurrentBranchName = async (user) => {
    const allStore = await (await API.getAllStore()).data;
    for (let i = 0; i < allStore.length; i++) {
      if (allStore[i]._id === user.default_store) {
        return allStore[i].branchName;
      }
    }
    return  allStore[0].branchName;
}

  renderCard = (step) => {
    const userId = this.state.step1Data.id
    switch (step) {
      case 0:
        return(
          <AuthForm
          authState={this.props.authState}
          toSignupStep1 = {(step1Data)=>this.toSignupStep1(step1Data)}
          onChangeAuthState={this.handleAuthState}
          onLogoClick={this.handleLogoClick}
          onLogin={(user) => this.onLoginAction(user)}
        />
        )
        break;
      case 1:
        return(
          <SignupFormStep1
            toSignupStep2 = {(data)=>this.toSignupStep2(data)}
          />
        )
      case 2:
        return(
          <SignupFormStep2
            userId = {userId}
            onLogin={(user) => this.onLoginAction(user)}            
          />
        )        
      default:
        break;
    }
  }

  render() {
    const bg = {
      backgroundImage: `url(${this.state[`bgImage${this.state.step+1}`]})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      minHeight:'100%',
      minWidth:'400px',
      width:'100%',
      height:'auto',
      position: 'fixed',
      top: 0,
      left:0
    };
    return (

        <Row
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            width: '99vw',
            marginTop: '3em'
          }}
          className = "mr-0 ml-0">
          <div style={bg}>
          </div>            
          {
            this.state.showLogForm
              ? <Col md={6} lg={4}>
                <Card body className="card-with-top-border">
                  {this.renderCard(this.state.step)}
                </Card>
              </Col>
              : null
          }

        </Row>        
    );
  }
}

function mapStateToProps(state) {
  return {
    userState: state.userData,
    storeState: state.allStoreList,
    currentStoreState: state.currentStore
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setCurrentRole: (data) => dispatch(setCurrentRole(data)),
    setCurrentStore: (data) => dispatch(setCurrentStore(data)),
    fetchTrialExpired: (data) => dispatch(fetchTrialExpired(data)),
    fetchActivePlanState: (data) => dispatch(fetchActivePlanState(data)),
    fetchAllContent: () => dispatch(fetchAllContent()),
    fetchDefaultLanguage: (data) => dispatch(fetchDefaultLanguage(data)),
    fetchPendingAppointmment: (data)=> dispatch(fetchPendingAppointmment(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AuthPage));
