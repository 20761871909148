import logo200Image from '../assets/img/logo/logo_200.png';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from "react-redux";
import { setUserData } from '../store/actions/action';
import Typography from '../components/Typography';
// import {toastFailure} from "../utils/toast_mixin";
import {
  Button, Form, FormGroup, Input, Label, Col
} from 'reactstrap';
import {
  CountryDropdown,
  RegionDropdown
} from "react-country-region-selector";
// import { Dial } from 'twilio/lib/twiml/VoiceResponse';

const CONSTANT = require('../utils/Const');

class SignupFormStep1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      streetAddress:"",
      country:"",
      state:"",
      city:"",
      newStoreAlert:""
    }
  }

  componentDidMount() {
  }

  handleStoreInputChange = (event) => {
    let { name, value } = event.target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        switch (name) {     
          default:
            break;
        }
        this.newStoreInputWarning()
      }
    );
  };
  newStoreInputWarning = () => {
    const { country, state} = this.state;
    let alertMessage = "";

    if ((!country)||(!state)) {
      alertMessage = alertMessage + "Country and Region are required. "
    };

    this.setState({ newStoreAlert: alertMessage });
  }

  selectCountry(val) {
    this.setState({ country: val }, ()=>this.newStoreInputWarning());
  }

  selectRegion(val) {
    this.setState({ state: val }, ()=>this.newStoreInputWarning());
  }


  render() {
    const {
      showLogo,
      onLogoClick,
      toSignupStep2
    } = this.props;

    return (
      <Form>
        {showLogo && (
          <div className="text-center pt-4 pb-4">
            <img
              src={logo200Image}
              className="rounded"
              style={{ width: 220, cursor: 'pointer' }}
              alt="logo"
              onClick={onLogoClick}
            />
          </div>
        )}
        <strong>Step 1/2: Where are your store</strong>
        <Typography className="text-muted small">This information is displayed on online booking page!</Typography>                          
        <FormGroup row className="mb-2">
          <Label sm={6} md={4}>
            Country:{" "}
          </Label>
          <Col sm={6} md={5}>
            <CountryDropdown
              style={{
                height: "calc(1.5em + 0.75rem + 2px)",
                width: "100%",
                borderRadius: 5,
              }}
              value={this.state.country}
              onChange={(val) => this.selectCountry(val)}
            />
          </Col>
        </FormGroup>
        <FormGroup row className="mb-2">
          <Label sm={6} md={4}>
            Region/State:{" "}
          </Label>
          <Col sm={6} md={5}>
            <RegionDropdown
              style={{
                height: "calc(1.5em + 0.75rem + 2px)",
                width: "100%",
                borderRadius: 5,
              }}
              country={this.state.country}
              value={this.state.state}
              onChange={(val) => this.selectRegion(val)}
            />
          </Col>
        </FormGroup>
        <FormGroup row className="mb-2">
          <Label sm={6} md={4}>
            City:{" "}
          </Label>
          <Col sm={6} md={5}>
            <Input
              type="text"
              value={this.state.city}
              onChange={this.handleStoreInputChange}
              name="city"
            />
          </Col>
        </FormGroup>
        <FormGroup row className="mb-2">
          <Label sm={6} md={4}>
            Store Address:{" "}
          </Label>
          <Col sm={6} md={5}>
            <Input
              type="text"
              value={this.state.streetAddress}
              onChange={this.handleStoreInputChange}
              name="streetAddress"
            />
          </Col>
        </FormGroup>
        <span className="alert_text">{this.state.newStoreAlert} </span>
        <Button
          disabled={!!this.state.newStoreAlert}
          size="lg"
          className="bg-gradient-theme-left border-0"
          block
          onClick={()=>toSignupStep2(this.state)}>
            Next Step
        </Button>               
      </Form>
    );
  }
}
SignupFormStep1.propTypes = {
  toSignupStep2: PropTypes.func,
  showLogo: PropTypes.bool,
  onLogoClick:PropTypes.func
  
};

SignupFormStep1.defaultProps = {
  showLogo: true,
  onLogoClick: () => { },
  toSignupStep2: () => { }
};

function mapStateToProps(state) {
  return {
    userState: state.userData
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setUserData: (data) => dispatch(setUserData(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignupFormStep1);
