import React from 'react';
import { Card, Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { setCurrentRole, setCurrentStore, setUserData } from '../store/actions/action';
import {
    withRouter
} from 'react-router-dom';
import {
    FormGroup, Input, Label, Button
} from 'reactstrap';
import API from '../utils/API';
class EmployeeAuth extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            showLogForm: false,
            branch_name: ''
        }
    }

    componentDidMount() {

    }

    handleSubmit = () => {
        const { branch_name } = this.state;
        API.createAccount({ branchName: branch_name })
            .then(result => {
                console.log('DATA:', result);
            })
            .catch(error => {
                console.log(error);
            })
    }

    onEmployeeLogin(employeeData) {
        // const data = { index: 0, id: employeeData.default_store };
        // this.props.setCurrentStore(data);
        // localStorage.setItem(CONSTANT.key.STORE, JSON.stringify(data));
        this.props.setCurrentRole('employee');
        setTimeout(() => {
            this.props.history.push('/staff/dashboard');
        }, 300);
    }

    render() {
        const { branch_name } = this.state;
        return (
            <Row
                style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '99vw'
                }}>
                <Col md={6} lg={4}>
                    <br />
                    <br />
                    <Card body>
                        <FormGroup>
                            <Label style={{ width: '100%', textAlign: 'center' }}>Create new branch</Label>
                            <Input
                                style={{ textAlign: 'center' }}
                                type='text'
                                placeholder='Enter branch name'
                                value={branch_name}
                                onChange={evt => this.setState({ branch_name: evt.target.value })} />
                        </FormGroup>
                        <hr />
                        <Button
                            disabled={branch_name.length === 0}
                            size="lg"
                            className="bg-gradient-theme-left border-0"
                            block
                            onClick={this.handleSubmit}>
                            Enter
                        </Button>
                    </Card>
                </Col>
            </Row>
        );
    }
}

function mapStateToProps(state) {
    return {
        userState: state.userData,
        storeState: state.allStoreList
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setCurrentRole: (data) => dispatch(setCurrentRole(data)),
        setCurrentStore: (data) => dispatch(setCurrentStore(data)),
        setUserData: (data) => dispatch(setUserData(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EmployeeAuth));
