import logo200Image from '../assets/img/logo/logo_200.png';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from "react-redux";
import { setUserData, setDefaultLanguage } from '../store/actions/action';
import {
  Button, Form, FormGroup, Input, Label, Col, Row, FormText
} from 'reactstrap';
import API from '../utils/API';
import {toastFailure} from "../utils/toast_mixin";

const CONSTANT = require('../utils/Const');
const LANGUAGE = CONSTANT.supportHelpLanguage;
const FULL_LANGUAGE = CONSTANT.fullHelpLangague;
class SignupFormStep2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname:"",
      lastname:"",
      inputAlert:"",
      language: "vi"
    }
  }

  componentDidMount() {
  }

  handleInputChange = (event) => {
    let { name, value } = event.target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        switch (name) {     
          default:
            break;
        }
        this.inputWarning()
      }
    );
  };

  inputWarning = () => {
    const { firstname, lastname} = this.state;
    let alertMessage = "";

    if ((!firstname)||(!lastname)) {
      alertMessage = alertMessage + "First Name and Last Name are required. "
    };

    this.setState({ inputAlert: alertMessage });
  }

  signon(userId) {
    let {
      firstname,
      lastname,
      language
    } = this.state;

    const newUserObject = {
      firstname,
      lastname,
    };    
    API.userUpdate(userId, newUserObject)
      .then(res => {
        API.getUserById(userId)
          .then(res2=>{
            API.updateUserLanguage(userId, {
              default_lang: language
            }).then(res3 => {
              const languageRes  = res3.data;
              if (languageRes.status) {
                let accData = res2.data.data
                localStorage.setItem(CONSTANT.key.USER, JSON.stringify(accData));
                this.props.setUserData(accData);
                this.props.onLogin(accData);
                this.props.setDefaultLanguage(language);
              } else {
                toastFailure("There something wrong on the server!");
              }
            })
          })
        
        // accData.acc_owner = true;
        // accData.manager_role = true;
        // accData.receptionist_role = true;
        // accData.customer_role = true;
        // accData.default_role = "Manager";
        // accData.account = this.state.defaultAccount;
        // console.log(accData);
        // localStorage.setItem(CONSTANT.key.USER, JSON.stringify(accData));
        // this.props.setUserData(accData);
        // this.props.onLogin(accData);
      })
      .catch(error => {
        console.log(error);
        toastFailure("There something wrong on the server!");
      }) 
  }


  render() {
    const {
      showLogo,
      userId,
      onLogoClick
    } = this.props;

    return (
      <Form>
        {showLogo && (
          <div className="text-center pt-4 pb-4">
            <img
              src={logo200Image}
              className="rounded"
              style={{ width: 220, cursor: 'pointer' }}
              alt="logo"
              onClick={onLogoClick}
            />
          </div>
        )}
        <strong>Step 2/2: Tell us about yourself</strong>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label for={"First Name"}>First Name*</Label>
              <Input
                type="text"
                name="firstname"
                placeholder="Your first name"
                value={this.state.firstname}
                onChange={this.handleInputChange}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for={"Last Name"}>Last Name*</Label>
              <Input
                type="text"
                placeholder="Your last name"
                name="lastname"
                value={this.state.lastname}
                onChange={this.handleInputChange}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row className = "mb-3">
          <Col sm={12}>
          <FormGroup>
            <Label for={"language"}>Default Language of Help Pages</Label>
             <Input type="select" name="language" onChange = {this.handleInputChange}>
                <option value = {LANGUAGE.vi}>{FULL_LANGUAGE[LANGUAGE.vi]}</option>
                <option value = {LANGUAGE.eng}>{FULL_LANGUAGE[LANGUAGE.eng]}</option>
                <option value = {LANGUAGE.span}>{FULL_LANGUAGE[LANGUAGE.span]}</option>
            </Input>
          </FormGroup>  
          </Col>
        </Row>

        <span className="alert_text">{this.state.inputAlert} </span>
        <Button
          disabled={!!this.state.inputAlert}
          size="lg"
          className="bg-gradient-theme-left border-0"
          block
          onClick={()=>this.signon(userId)}>
            Go to my store
        </Button>               
      </Form>
    );
  }
}
SignupFormStep2.propTypes = {
  onLogoClick:PropTypes.func,
  showLogo: PropTypes.bool,
  userId:PropTypes.string,
};

SignupFormStep2.defaultProps = {
  showLogo: true,
  userId:"",
  onLogoClick: () => { },
};

function mapStateToProps(state) {
  return {
    userState: state.userData
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setUserData: (data) => dispatch(setUserData(data)),
    setDefaultLanguage: (data) => dispatch(setDefaultLanguage(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignupFormStep2);
